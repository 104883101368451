import React from 'react';
import styles from './ComplexTableAssetCell.module.scss';
import { Chevron, CountryIso3, Flag, Typography } from '@components';
import { colors } from '@componentsStyles';

interface ComplexTableAssetCell {
  country: CountryIso3;
  place: string;
  address: string;
  type: string;
  types: string[];
}

export const ComplexTableAssetCell: React.FC<ComplexTableAssetCell> = ({
  country,
  place,
  address,
  type,
  types
}) => {
  const segmentColors = [
    // 60
    colors.segmentBlue60,
    colors.segmentGreen60,
    colors.segmentCyan60,
    colors.segmentPurple60,
    colors.segmentGold60,
    colors.segmentOrange60,
    colors.segmentRed60,
    colors.segmentBlack60,
    // 100
    colors.segmentBlue100,
    colors.segmentGreen100,
    colors.segmentCyan100,
    colors.segmentPurple100,
    colors.segmentGold100,
    colors.segmentOrange100,
    colors.segmentRed100,
    colors.segmentBlack100,
    // 40
    colors.segmentBlue40,
    colors.segmentGreen40,
    colors.segmentCyan40,
    colors.segmentPurple40,
    colors.segmentGold40,
    colors.segmentOrange40,
    colors.segmentRed40,
    colors.segmentBlack40,
    // 80
    colors.segmentBlue80,
    colors.segmentGreen80,
    colors.segmentCyan80,
    colors.segmentPurple80,
    colors.segmentGold80,
    colors.segmentOrange80,
    colors.segmentRed80,
    colors.segmentBlack80,
    // 20
    colors.segmentBlue20,
    colors.segmentGreen20,
    colors.segmentCyan20,
    colors.segmentPurple20,
    colors.segmentGold20,
    colors.segmentOrange20,
    colors.segmentRed20,
    colors.segmentBlack20
  ];

  // Additional color in case if there are more types
  const defaultColor = '#8F83CA';

  const getChevronColor = () => {
    const index = types?.findIndex((typeEl) => typeEl === type);
    return segmentColors?.[index] || defaultColor;
  };

  return (
    <div className={styles.assetCellWrapper}>
      <Flag country={country} width={14} height={10} className={styles.flag} />
      <div>
        <div className={styles.assetCellContent}>
          <Typography className={styles.title} variant={'body-5'}>
            {place}
          </Typography>
          <Chevron backgroundColor={getChevronColor()}>{type}</Chevron>
        </div>
        <Typography variant={'body-6'} color={colors.gray80Color}>
          {address}
        </Typography>
      </div>
    </div>
  );
};

export default ComplexTableAssetCell;
