import { ComplexTableSortState, Icon } from '@xq/ui-kit';
import cn from 'classnames';
import React from 'react';
import styles from '../ModelTreeAssetsTable.module.scss';
import { columnsSortKeyConfig } from '@pages/ModelTreeAssets/components/ModelTreeAssetsTable/utils/getDataColumns';

export const mapSortFromKeyToSorterKey = (key: string) => {
  if (!key) return null;
  return columnsSortKeyConfig.find((el) => el.key === key)?.sorterKey;
};

export const mapSortFromSortedKeyToKey = (sorterKey: string) => {
  if (!sorterKey) return null;
  return columnsSortKeyConfig.find((el) => el.sorterKey === sorterKey)?.key;
};

export const mapSortOrderFromAntToBackend = (
  sortOrder: 'ascend' | 'descend'
): 'ASC' | 'DESC' => {
  return sortOrder === 'ascend' ? 'ASC' : 'DESC';
};

export const mapSortOrderFromBackendToAnt = (
  sortOrder: 'ASC' | 'DESC'
): 'ascend' | 'descend' => {
  return sortOrder === 'ASC' ? 'ascend' : 'descend';
};

export const getSortOptions = (
  sortState: ComplexTableSortState,
  cellName: string
) => {
  return {
    // TODO: maybe we need to reset sort when turn of the row? This not working now
    sorter: (
      a: { [key: string]: { place: string } | string | number },
      b: { [key: string]: { place: string } | string | number }
    ) => {},

    sortDirections: ['descend' as const, 'ascend' as const, 'descend' as const],
    defaultSortOrder: 'ascend' as const,
    sortIcon: ({ sortOrder }: { sortOrder: string }) => (
      <Icon
        size="xs"
        className={cn(styles.sortIcon, {
          [styles.sortIconActive]: sortState?.columnKey === cellName
        })}
        name={sortOrder !== 'descend' ? 'sort-less' : 'sort-more'}
      />
    )
  };
};

const sortOnClientSideCallback = (
  cellName: string,
  a: { [key: string]: { place: string } | string | number },
  b: { [key: string]: { place: string } | string | number }
) => {
  // TODO: maybe improve?
  if (
    typeof a[cellName] === 'object' &&
    a[cellName] !== null &&
    // todo fix
    // @ts-ignore
    'place' in a[cellName] &&
    typeof b[cellName] === 'object' &&
    b[cellName] !== null &&
    // todo fix
    // @ts-ignore
    'place' in b[cellName]
  ) {
    return (a[cellName] as { place: string }).place?.localeCompare(
      (b[cellName] as { place: string }).place
    );
  }

  if (typeof a[cellName] === 'number' && typeof b[cellName] === 'number') {
    // todo fix
    // @ts-ignore
    return a[cellName] - b[cellName];
  }

  return a[cellName]?.toString()?.localeCompare(b[cellName]?.toString());
};
