import React, { useEffect, useState } from 'react';
import { Dropdown as DropdownAntd } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './ComplexTableDropdown.module.scss';
import cn from 'classnames';
import { Icon } from '@components/Icon';

interface DropdownProps {
  title: string;
  items: { selected: boolean; title: string; value: string }[];
  icon: React.ReactNode;
  onItemSelect: (value: string) => void;
  placement: 'bottomLeft' | 'bottomRight';
}

export const ComplexTableDropdown: React.FC<DropdownProps> = ({
  title,
  items: initialItems,
  icon,
  onItemSelect,
  placement
}) => {
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const handleToggle = (value: string) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.value === value ? { ...item, selected: !item.selected } : item
      )
    );

    onItemSelect && onItemSelect(value);
  };

  const sortedItems = items.slice().sort((a, b) => {
    if (a.selected === b.selected) {
      return 0;
    }
    return a.selected ? -1 : 1;
  });

  const menuItems = (
    <div className={styles.dropdownContainerItems}>
      {sortedItems.map(({ title, value, selected }) => (
        <div
          className={styles.dropdownContainerItemsItem}
          key={value}
          onClick={() => handleToggle(value)}
        >
          <Icon
            className={cn({ [styles.selected]: selected })}
            size="s"
            name="check"
          />

          {title}
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.dropdownWrapper}>
      <DropdownAntd
        placement={placement}
        dropdownRender={(originNode) => (
          <div
            className={cn(styles.dropdownContainer, {
              [styles.left]: placement === 'bottomLeft'
            })}
          >
            <p>{title}</p>
            {menuItems}
            {originNode}
          </div>
        )}
        trigger={['click']}
      >
        <div>
          {icon}
          <DownOutlined />
        </div>
      </DropdownAntd>
    </div>
  );
};

export default ComplexTableDropdown;
