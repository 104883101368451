import { ActiveRow, KpiColumn } from '../interface';
import {
  colors,
  ComplexTableKpiTypography,
  ComplexTableSortState,
  Typography
} from '@xq/ui-kit';
import React from 'react';
import styles from '../ModelTreeAssetsTable.module.scss';
import { getSortOptions } from './getSortOptions';

export const groups = {
  group_1: 'KPI group 1',
  group_2: 'KPI group 2',
  group_3: 'KPI group 3'
};

// kpi config
// todo rename and create function for creating this?
// todo maybe get part of this from BE
const kpiColumnsConfig = [
  {
    key: 'gla_sqm',
    title: <>GLA, sqm</>,
    label: 'GLA',
    unit: 'sqm',
    groups: ['group_1', 'group_3']
  },
  {
    key: 'vacancy_rate_tech',
    title: (
      <>
        Vacancy Rate <br /> (tech.), %
      </>
    ),
    label: 'Vacancy Rate (tech.)',
    unit: '%',
    groups: ['group_1', 'group_2', 'group_3']
  },
  {
    key: 'vacancy_rate_econ',
    title: (
      <>
        Vacancy Rate <br /> (econ.), %
      </>
    ),
    label: 'Vacancy Rate (econ.)',
    unit: '%',
    groups: ['group_1', 'group_2', 'group_3']
  },
  {
    key: 'lease_length_years',
    title: (
      <>
        Lease Length, <br /> years %
      </>
    ),
    label: 'Lease Length',
    unit: 'years %',
    groups: ['group_1', 'group_3']
  }
];

/**
 * Get columns for KPI section of the table (right side)
 * @param activeRowIndexes
 * @param activeKpi
 * @param sortState
 */
export const getKpiColumns = (
  activeRowIndexes: ActiveRow[] | undefined,
  activeKpi: string,
  sortState: ComplexTableSortState | undefined
): KpiColumn[] => {
  const columns: KpiColumn[] = [];

  kpiColumnsConfig.forEach(({ key, label, unit, title, groups: groupKeys }) => {
    //todo why do we need kpi for every group?
    groupKeys.forEach((groupKey) => {
      columns.push({
        hidden: false,
        //todo fix
        // @ts-ignore
        group: { key: groupKey, label: groups[groupKey] },
        title: () => (
          <Typography variant="system-heading" color={colors.gray80Color}>
            {title}
          </Typography>
        ),
        displayTitle: `${label}, ${unit}`,
        id: `${key}_${groupKey}`,
        dataIndex: `${key}_${groupKey}`,
        key: `${key}_${groupKey}`,
        className: styles.kpiHeaderCell,
        width: 120,
        ...getSortOptions(sortState, `${key}_${groupKey}`),
        //todo add type
        render: (value: string, data: Record<string, any>) =>
          renderCell(value, data, activeRowIndexes, activeKpi)
      });
    });
  });

  return columns;
};

/**
 *  Function for rendering the cell
 * @param value
 * @param data
 * @param activeRowIndexes
 * @param activeKpi
 */
function renderCell(
  value: string,
  //todo fix any
  data: Record<string, any>, // todo use T ?
  activeRowIndexes: ActiveRow[] | undefined,
  activeKpi: string
) {
  const key = (data.asset_name?.place + data.asset_name?.country)
    ?.toLowerCase()
    .replaceAll(' ', '_');

  const activeRow = activeRowIndexes?.find((row) => row.key === key);

  return (
    <ComplexTableKpiTypography
      activeRow={activeRow}
      data={data}
      activeKpi={activeKpi}
      value={value}
    />
  );
}
