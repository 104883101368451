import { colors } from '@xq/ui-kit';

export const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const DASH = '–';

export const chartColors = [
  colors.palettesBlue20,
  colors.palettesOrange30,
  colors.palettesGreen20,
  colors.palettesPurple20
];

/* heatmap colors from low to high */
export const heatmapColors = [
  ['#99C2FF', '#5E9EFF', '#2B80FF', '#0063F7', '#0052CD', '#003D99'], // blue
  ['#FFD099', '#FFB55E', '#FF9E2B', '#FA8800', '#E77D00', '#C96D00'], // orange
  ['#27EDAB', '#00DC93', '#00C684', '#00B075', '#009D68', '#00744D'], // green
  ['#E8C6FA', '#DFA0FF', '#D177FF', '#C34EFF', '#AF1FF8', '#8419BA'] // purple
];

export const EXQUANCE_INSIGHTS_LICENSE_UUID =
  'b4749d52-34ec-4905-8c0b-ca56fc9c0dca';

export enum ProviderNames {
  newsec = 'newsec',
  kti = 'kti'
}

export const ITEMS_PER_PAGE = 20;
