import { useMap } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { MapClusterMarker } from './MapClusterMarker';
import { MarkerItem } from '@components';
import {
  ClusterStats,
  Renderer
} from '@googlemaps/markerclusterer/dist/renderer';
import { Cluster } from '@googlemaps/markerclusterer/dist/cluster';

export type ClusteredMarkersProps = {
  markersData: MarkerItem[];
};

export const ClusteredMarkers = ({ markersData }: ClusteredMarkersProps) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  const selectedTree = useMemo(
    () =>
      markersData && selectedKey
        ? markersData.find((t) => t.key === selectedKey)!
        : null,
    [markersData, selectedKey]
  );

  const map = useMap();

  const markerRenderer: Renderer = {
    render(
      cluster: Cluster,
      stats: ClusterStats,
      map: google.maps.Map
    ): Marker {
      const getContent = (count: number) => {
        const content = document.createElement('div');

        content.style.padding = '7px 12px';
        content.style.borderRadius = '50px';
        content.style.backgroundColor = 'rgba(255, 255, 255, 0.80)';
        content.style.display = 'flex';
        content.style.alignItems = 'center';
        content.style.justifyContent = 'center';
        content.style.color = '#121212';
        content.style.fontSize = '12px';
        content.style.lineHeight = '16px';
        content.style.fontFamily = 'Segoe UI, sans-serif';
        content.style.fontWeight = '700';
        content.style.letterSpacing = '0.24px';

        content.innerText = count.toString();
        return content;
      };

      return new google.maps.marker.AdvancedMarkerElement({
        position: cluster.position,
        content: getContent(cluster.count)
      });
    }
  };

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map, renderer: markerRenderer });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
    console.log('clusterer', clusterer);
    console.log('markers', markers);
  }, [clusterer, markers]);

  // this callback will effectively get passed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleMarkerClick = useCallback((markerItem: MarkerItem) => {
    setSelectedKey(markerItem.key);
  }, []);

  return (
    <>
      {markersData.map((markerItem) => (
        <MapClusterMarker
          key={markerItem.key}
          markerItem={markerItem}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}
    </>
  );
};
