import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const GridSettingsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.15164 0.880859C3.51891 0.880859 3.81664 1.17859 3.81664 1.54586V5.78828C3.81664 6.15555 3.51891 6.45328 3.15164 6.45328C2.78437 6.45328 2.48664 6.15555 2.48664 5.78828V1.54586C2.48664 1.17859 2.78437 0.880859 3.15164 0.880859ZM8.00009 0.880859C8.36736 0.880859 8.66509 1.17859 8.66509 1.54586V3.9114H9.81828C10.1855 3.9114 10.4833 4.20913 10.4833 4.5764C10.4833 4.94367 10.1855 5.2414 9.81828 5.2414H6.18191C5.81464 5.2414 5.51691 4.94367 5.51691 4.5764C5.51691 4.20913 5.81464 3.9114 6.18191 3.9114H7.33509V1.54586C7.33509 1.17859 7.63282 0.880859 8.00009 0.880859ZM12.8485 0.880859C13.2158 0.880859 13.5135 1.17859 13.5135 1.54586V7.0004C13.5135 7.36767 13.2158 7.6654 12.8485 7.6654C12.4813 7.6654 12.1835 7.36767 12.1835 7.0004V1.54586C12.1835 1.17859 12.4813 0.880859 12.8485 0.880859ZM8.00009 6.3354C8.36736 6.3354 8.66509 6.63314 8.66509 7.0004V12.455C8.66509 12.8222 8.36736 13.12 8.00009 13.12C7.63282 13.12 7.33509 12.8222 7.33509 12.455V7.0004C7.33509 6.63314 7.63282 6.3354 8.00009 6.3354ZM0.668457 8.21276C0.668457 7.84549 0.966188 7.54776 1.33346 7.54776H4.96982C5.33709 7.54776 5.63482 7.84549 5.63482 8.21276C5.63482 8.58003 5.33709 8.87776 4.96982 8.87776H3.81664V12.4552C3.81664 12.8225 3.51891 13.1202 3.15164 13.1202C2.78437 13.1202 2.48664 12.8225 2.48664 12.4552V8.87776H1.33346C0.966188 8.87776 0.668457 8.58003 0.668457 8.21276ZM10.3654 9.42423C10.3654 9.05696 10.6631 8.75923 11.0304 8.75923H14.6667C15.034 8.75923 15.3317 9.05696 15.3317 9.42423C15.3317 9.7915 15.034 10.0892 14.6667 10.0892H13.5135V12.4547C13.5135 12.822 13.2158 13.1197 12.8485 13.1197C12.4813 13.1197 12.1835 12.822 12.1835 12.4547V10.0892H11.0304C10.6631 10.0892 10.3654 9.7915 10.3654 9.42423Z"
    />
  </SvgIcon>
);

GridSettingsIcon.displayName = 'GridSettingsIcon';
