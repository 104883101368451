import React, { FC, useEffect } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Coordinates } from '@components/Map/dataTypes';
import { MapClusterMap } from '@components/MapCluster/components/MapClusterMap';

export interface MapClusterProps {
  googleMapsApiKey: string;
  markersData: MarkerItem[];
  options?: {
    disableDefaultUI?: boolean;
    fullscreenControl?: boolean;
    streetViewControl?: boolean;
    zoomControl?: boolean;
    mapTypeControl?: boolean;
  };
  mapId: string;
}

export interface MarkerItem {
  key: string;
  title: string;
  coordinates: Coordinates;
  backgroundColor: string;
}

/**
 * The App component contains the APIProvider, Map and ControlPanel and handles
 * data-loading and filtering.
 */
export const MapCluster: FC<MapClusterProps> = (props) => {
  const {
    googleMapsApiKey,
    markersData,
    options = { disableDefaultUI: true },
    mapId
  } = props;

  useEffect(() => {
    console.log('markersData', markersData);
  }, [markersData]);

  return (
    <div>
      <APIProvider apiKey={googleMapsApiKey}>
        <MapClusterMap
          markersData={markersData}
          options={options}
          mapId={mapId}
        />
      </APIProvider>
    </div>
  );
};

MapCluster.displayName = 'MapCluster';
