import React, { FC } from 'react';
import styles from './ComplexTableRadio.module.scss';

interface KpiRadioProps {
  name: string;
  checked: boolean;
  displaySelectors: boolean;
  title: FC;
  onClick: (event: React.MouseEvent<HTMLLabelElement>) => void;
}

export const ComplexTableKpiRadio: FC<KpiRadioProps> = ({
  name,
  checked,
  displaySelectors,
  title,
  onClick
}) => {
  return (
    <label className={styles.kpiContainer} onClick={onClick}>
      <div className={styles.kpiRadioPlaceholder}>
        {displaySelectors && (
          <>
            <input type="radio" name={name} checked={checked} />
            <div className={styles.checkmark} />
          </>
        )}
      </div>
      {title({})}
    </label>
  );
};
