import { DataItem } from '@pages/ModelTreeAssets/interface';
import { ListMetaInfo } from '@interfaces';

const tableData: DataItem[] = [
  {
    key: '1',
    asset_name: {
      country: 'DEU',
      place: 'Blaumarktpoint',
      address: 'Rathausmarkt 7',
      type: 'retail'
    },
    number: 1007,
    city: 'Hamburg',
    project: 'Project TT',
    submarket: 'Central',
    last_analysis_date: '2021-09-01',
    gla_sqm_group_1: '13,086.0',
    vacancy_rate_tech_group_1: '8,016.0',
    vacancy_rate_econ_group_1: '0',
    lease_length_years_group_1: '2,926.37',
    gla_sqm_group_2: '11,086.0',
    vacancy_rate_tech_group_2: '7,016.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '3,926.37',
    gla_sqm_group_3: '11,086.0',
    vacancy_rate_tech_group_3: '9,016.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '3,926.37'
  },
  {
    key: '2',
    asset_name: {
      country: 'SWE',
      place: 'Buena Vista Offices',
      address: 'Stallgatan 9',
      type: 'office'
    },
    number: 42,
    project: 'Project AA',
    city: 'Stockholm',
    submarket: 'Stockholm',
    last_analysis_date: '2021-09-01',
    gla_sqm_group_1: '13,086.0',
    vacancy_rate_tech_group_1: '8,016.0',
    vacancy_rate_econ_group_1: '0',
    lease_length_years_group_1: '2,926.37',
    gla_sqm_group_2: '11,086.0',
    vacancy_rate_tech_group_2: '7,016.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '3,926.37',
    gla_sqm_group_3: '11,086.0',
    vacancy_rate_tech_group_3: '11,016.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '3,926.37'
  },
  {
    key: '3',
    asset_name: {
      country: 'AUS',
      place: 'Central Business Hub',
      address: 'Sidney Liberty street 2',
      type: 'retail'
    },
    project: 'Nero',
    number: 32,
    city: 'Sidney',
    submarket: 'Sidney',
    last_analysis_date: '2021-09-01',
    gla_sqm_group_1: '13,086.0',
    vacancy_rate_tech_group_1: '8,016.0',
    vacancy_rate_econ_group_1: '0',
    lease_length_years_group_1: '2,926.37',
    gla_sqm_group_2: '11,086.0',
    vacancy_rate_tech_group_2: '7,016.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '3,926.37',
    gla_sqm_group_3: '11,086.0',
    vacancy_rate_tech_group_3: '12,016.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '3,926.37'
  },
  {
    key: '4',
    asset_name: {
      country: 'FIN',
      place: 'Valdemar Center',
      address: 'Valdemarsgade',
      type: 'logistics'
    },
    project: 'Project BB',
    number: 32,
    city: 'Sidney',
    submarket: 'Sidney',
    gla_sqm_group_1: '13,086.0',
    vacancy_rate_tech_group_1: '8,016.0',
    vacancy_rate_econ_group_1: '0',
    lease_length_years_group_1: '2,926.37',
    gla_sqm_group_2: '11,086.0',
    vacancy_rate_tech_group_2: '7,016.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '3,926.37',
    gla_sqm_group_3: '11,086.0',
    vacancy_rate_tech_group_3: '7,016.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '3,926.37'
  },
  {
    key: '14',
    asset_name: {
      country: 'USA',
      place: 'Liberty Plaza',
      address: '45 Liberty St',
      type: 'office'
    },
    number: 230,
    project: 'Liberty Project',
    city: 'New York',
    submarket: 'Downtown',
    last_analysis_date: '2021-06-10',
    gla_sqm_group_1: '22,500.0',
    vacancy_rate_tech_group_1: '12,000.0',
    vacancy_rate_econ_group_1: '0',
    lease_length_years_group_1: '5,000.00',
    gla_sqm_group_2: '20,000.0',
    vacancy_rate_tech_group_2: '10,000.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '4,500.00',
    gla_sqm_group_3: '18,000.0',
    vacancy_rate_tech_group_3: '9,000.0',
    vacancy_rate_econ_group_3: '1',
    lease_length_years_group_3: '4,000.00'
  },
  {
    key: '15',
    asset_name: {
      country: 'ESP',
      place: 'Plaza Central',
      address: 'Gran Via 25',
      type: 'retail'
    },
    number: 80,
    project: 'Sun Plaza',
    city: 'Madrid',
    submarket: 'Gran Via',
    last_analysis_date: '2023-02-20',
    gla_sqm_group_1: '15,500.0',
    vacancy_rate_tech_group_1: '7,500.0',
    vacancy_rate_econ_group_1: '2',
    lease_length_years_group_1: '3,200.00',
    gla_sqm_group_2: '14,000.0',
    vacancy_rate_tech_group_2: '6,000.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '3,000.00',
    gla_sqm_group_3: '12,000.0',
    vacancy_rate_tech_group_3: '5,500.0',
    vacancy_rate_econ_group_3: '3',
    lease_length_years_group_3: '2,800.00'
  },
  {
    key: '16',
    asset_name: {
      country: 'ITA',
      place: 'Roma Square',
      address: 'Via Condotti 50',
      type: 'logistics'
    },
    number: 300,
    project: 'Italia Hub',
    city: 'Rome',
    submarket: 'Centro',
    last_analysis_date: '2022-10-01',
    gla_sqm_group_1: '11,500.0',
    vacancy_rate_tech_group_1: '5,500.0',
    vacancy_rate_econ_group_1: '1',
    lease_length_years_group_1: '3,500.00',
    gla_sqm_group_2: '10,000.0',
    vacancy_rate_tech_group_2: '4,500.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '3,200.00',
    gla_sqm_group_3: '9,000.0',
    vacancy_rate_tech_group_3: '4,000.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '2,800.00'
  },
  {
    key: '17',
    asset_name: {
      country: 'FRA',
      place: 'Champs Complex',
      address: 'Avenue Champs-Élysées 50',
      type: 'office'
    },
    number: 550,
    project: 'Paris Business',
    city: 'Paris',
    submarket: 'Central',
    last_analysis_date: '2023-07-01',
    gla_sqm_group_1: '16,500.0',
    vacancy_rate_tech_group_1: '9,000.0',
    vacancy_rate_econ_group_1: '1',
    lease_length_years_group_1: '4,500.00',
    gla_sqm_group_2: '15,000.0',
    vacancy_rate_tech_group_2: '8,000.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '4,200.00',
    gla_sqm_group_3: '14,000.0',
    vacancy_rate_tech_group_3: '7,500.0',
    vacancy_rate_econ_group_3: '3',
    lease_length_years_group_3: '4,000.00'
  },
  {
    key: '111',
    asset_name: {
      country: 'JPN',
      place: 'Shibuya Crossing Center',
      address: 'Shibuya 3-21-1',
      type: 'retail'
    },
    number: 90,
    project: 'Shibuya Central',
    city: 'Tokyo',
    submarket: 'Shibuya',
    last_analysis_date: '2022-08-15',
    gla_sqm_group_1: '12,400.0',
    vacancy_rate_tech_group_1: '5,600.0',
    vacancy_rate_econ_group_1: '1',
    lease_length_years_group_1: '3,400.00',
    gla_sqm_group_2: '11,200.0',
    vacancy_rate_tech_group_2: '5,200.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '3,200.00',
    gla_sqm_group_3: '10,500.0',
    vacancy_rate_tech_group_3: '4,800.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '3,100.00'
  },
  {
    key: '112',
    asset_name: {
      country: 'BRA',
      place: 'Sao Paulo Tower',
      address: 'Avenida Paulista 1000',
      type: 'office'
    },
    number: 125,
    project: 'Paulista Offices',
    city: 'São Paulo',
    submarket: 'Paulista',
    last_analysis_date: '2023-09-10',
    gla_sqm_group_1: '16,800.0',
    vacancy_rate_tech_group_1: '8,400.0',
    vacancy_rate_econ_group_1: '2',
    lease_length_years_group_1: '4,200.00',
    gla_sqm_group_2: '15,200.0',
    vacancy_rate_tech_group_2: '7,600.0',
    vacancy_rate_econ_group_2: '3',
    lease_length_years_group_2: '4,000.00',
    gla_sqm_group_3: '14,000.0',
    vacancy_rate_tech_group_3: '7,200.0',
    vacancy_rate_econ_group_3: '1',
    lease_length_years_group_3: '3,900.00'
  },
  {
    key: '113',
    asset_name: {
      country: 'SGP',
      place: 'Orchard Road Plaza',
      address: 'Orchard Rd 500',
      type: 'retail'
    },
    number: 75,
    project: 'Orchard Gateway',
    city: 'Singapore',
    submarket: 'Orchard',
    last_analysis_date: '2022-05-18',
    gla_sqm_group_1: '13,700.0',
    vacancy_rate_tech_group_1: '6,800.0',
    vacancy_rate_econ_group_1: '2',
    lease_length_years_group_1: '3,500.00',
    gla_sqm_group_2: '12,500.0',
    vacancy_rate_tech_group_2: '6,200.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '3,300.00',
    gla_sqm_group_3: '11,800.0',
    vacancy_rate_tech_group_3: '5,700.0',
    vacancy_rate_econ_group_3: '3',
    lease_length_years_group_3: '3,100.00'
  },
  {
    key: '114',
    asset_name: {
      country: 'ZAF',
      place: 'Cape Town Logistics Hub',
      address: 'Long Street 20',
      type: 'logistics'
    },
    number: 300,
    project: 'Cape Logistics',
    city: 'Cape Town',
    submarket: 'Industrial',
    last_analysis_date: '2021-10-05',
    gla_sqm_group_1: '20,000.0',
    vacancy_rate_tech_group_1: '10,000.0',
    vacancy_rate_econ_group_1: '0',
    lease_length_years_group_1: '5,000.00',
    gla_sqm_group_2: '18,000.0',
    vacancy_rate_tech_group_2: '9,000.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '4,800.00',
    gla_sqm_group_3: '16,500.0',
    vacancy_rate_tech_group_3: '8,500.0',
    vacancy_rate_econ_group_3: '1',
    lease_length_years_group_3: '4,500.00'
  },
  {
    key: '115',
    asset_name: {
      country: 'RUS',
      place: 'Red Square Mall',
      address: 'Tverskaya St 12',
      type: 'retail'
    },
    number: 95,
    project: 'Moscow Central',
    city: 'Moscow',
    submarket: 'Tverskoy',
    last_analysis_date: '2023-04-12',
    gla_sqm_group_1: '14,200.0',
    vacancy_rate_tech_group_1: '7,100.0',
    vacancy_rate_econ_group_1: '2',
    lease_length_years_group_1: '3,700.00',
    gla_sqm_group_2: '13,000.0',
    vacancy_rate_tech_group_2: '6,500.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '3,500.00',
    gla_sqm_group_3: '12,200.0',
    vacancy_rate_tech_group_3: '6,200.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '3,200.00'
  },
  {
    key: '21',
    asset_name: {
      country: 'NZL',
      place: 'Auckland Business Hub',
      address: 'Queen Street 120',
      type: 'office'
    },
    number: 75,
    city: 'Auckland',
    project: 'Kiwi Center',
    submarket: 'Downtown',
    last_analysis_date: '2023-08-01',
    gla_sqm_group_1: '10,500.0',
    vacancy_rate_tech_group_1: '5,500.0',
    vacancy_rate_econ_group_1: '1',
    lease_length_years_group_1: '3,000.00',
    gla_sqm_group_2: '9,500.0',
    vacancy_rate_tech_group_2: '4,800.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '2,800.00',
    gla_sqm_group_3: '8,900.0',
    vacancy_rate_tech_group_3: '4,200.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '2,500.00'
  },
  {
    key: '22',
    asset_name: {
      country: 'JPN',
      place: 'Tokyo Skyline Complex',
      address: 'Shibuya Crossing 21',
      type: 'retail'
    },
    number: 150,
    city: 'Tokyo',
    project: 'Skyline Plaza',
    submarket: 'Shibuya',
    last_analysis_date: '2023-09-15',
    gla_sqm_group_1: '17,000.0',
    vacancy_rate_tech_group_1: '7,500.0',
    vacancy_rate_econ_group_1: '2',
    lease_length_years_group_1: '4,200.00',
    gla_sqm_group_2: '16,000.0',
    vacancy_rate_tech_group_2: '7,000.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '4,000.00',
    gla_sqm_group_3: '15,500.0',
    vacancy_rate_tech_group_3: '6,500.0',
    vacancy_rate_econ_group_3: '3',
    lease_length_years_group_3: '3,800.00'
  },
  {
    key: '23',
    asset_name: {
      country: 'SGP',
      place: 'Marina Bay Tower',
      address: 'Marina Blvd 10',
      type: 'office'
    },
    number: 95,
    city: 'Singapore',
    project: 'Marina Project',
    submarket: 'Marina Bay',
    last_analysis_date: '2023-10-20',
    gla_sqm_group_1: '19,000.0',
    vacancy_rate_tech_group_1: '8,500.0',
    vacancy_rate_econ_group_1: '1',
    lease_length_years_group_1: '5,000.00',
    gla_sqm_group_2: '18,500.0',
    vacancy_rate_tech_group_2: '8,000.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '4,800.00',
    gla_sqm_group_3: '17,800.0',
    vacancy_rate_tech_group_3: '7,800.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '4,500.00'
  },
  {
    key: '24',
    asset_name: {
      country: 'BRA',
      place: 'São Paulo Trade Center',
      address: 'Avenida Paulista 100',
      type: 'logistics'
    },
    number: 180,
    city: 'São Paulo',
    project: 'Trade Hub',
    submarket: 'Paulista',
    last_analysis_date: '2023-11-05',
    gla_sqm_group_1: '20,000.0',
    vacancy_rate_tech_group_1: '9,500.0',
    vacancy_rate_econ_group_1: '1',
    lease_length_years_group_1: '5,200.00',
    gla_sqm_group_2: '19,000.0',
    vacancy_rate_tech_group_2: '9,000.0',
    vacancy_rate_econ_group_2: '1',
    lease_length_years_group_2: '5,000.00',
    gla_sqm_group_3: '18,500.0',
    vacancy_rate_tech_group_3: '8,500.0',
    vacancy_rate_econ_group_3: '2',
    lease_length_years_group_3: '4,800.00'
  },
  {
    key: '25',
    asset_name: {
      country: 'ZAF',
      place: 'Cape Town Waterfront',
      address: 'Dock Road 50',
      type: 'retail'
    },
    number: 45,
    city: 'Cape Town',
    project: 'Waterfront Plaza',
    submarket: 'V&A Waterfront',
    last_analysis_date: '2023-12-01',
    gla_sqm_group_1: '14,000.0',
    vacancy_rate_tech_group_1: '6,800.0',
    vacancy_rate_econ_group_1: '2',
    lease_length_years_group_1: '3,700.00',
    gla_sqm_group_2: '13,500.0',
    vacancy_rate_tech_group_2: '6,500.0',
    vacancy_rate_econ_group_2: '2',
    lease_length_years_group_2: '3,500.00',
    gla_sqm_group_3: '12,800.0',
    vacancy_rate_tech_group_3: '6,000.0',
    vacancy_rate_econ_group_3: '3',
    lease_length_years_group_3: '3,300.00'
  }
];

const tableDataMetaMock: ListMetaInfo = {
  totalItems: 50,
  totalPages: 5,
  itemsPerPage: 10,
  currentPage: 2,
  itemCount: 50
};

export const modelTreeAssetsMocks = {
  tableData,
  tableDataMetaMock
};
