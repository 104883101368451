import { DataItem } from '@pages/ModelTreeAssets/interface';
import interceptor from '@services/http';
import { wait } from '@xq/ui-kit';
import { modelTreeAssetsMocks } from '@pages/ModelTreeAssets/mocks';
import { ListParams } from '@interfaces';
import {
  ModelTreeAssetsApi,
  ModelTreeGlobalAssetListSortOptionsDTOFieldNameEnum,
  XQPaginationMetaDTO
} from '@xq/exquance-insights-gateway-frontend-client';
import { v4 as uuidv4 } from 'uuid';
import { exquanceInsightsApiConfiguration } from '@services';

interface TableDataResponse {
  meta: XQPaginationMetaDTO;
  items: Array<DataItem>; //todo change to real BE interface
}

export interface ModelTreeAssetsService {
  fetchTableData(queryParams: ListParams): Promise<TableDataResponse>;
  fetchPageData(): Promise<string[]>;
}

const insightsGateway = new ModelTreeAssetsApi(
  exquanceInsightsApiConfiguration
);

export class ModelTreeAssetsServiceApi implements ModelTreeAssetsService {
  async fetchTableData(queryParams: ListParams): Promise<TableDataResponse> {
    const response =
      await insightsGateway.modelTreeAssetsControllerGetGlobalAssets({
        modelTreeGlobalAssetListOptionsDTORequest: {
          pagination: {
            page: queryParams.page,
            limit: queryParams.limit
          },
          sort: {
            order: queryParams.sortOrder,
            fieldName:
              queryParams.sortBy as ModelTreeGlobalAssetListSortOptionsDTOFieldNameEnum
          }
        },
        searchString: queryParams.searchValue
      });
    return {
      meta: response.meta,
      items: response.items.map((item) => {
        return {
          key: item.assetUuid,
          asset_name: {
            country: item.assetCountryIso3,
            place: item.assetName,
            address: item.assetAddress,
            type: item.assetSegmentName
          },
          number: item.assetNumber,
          project: item.projectName,
          city: item.assetCityName,
          submarket: item.assetSubmarketName,
          last_analysis_date: item.valuationDate
            ? item.valuationDate.toLocaleDateString('ru-RU')
            : null,
          assetLatitude: item.assetLatitude,
          assetLongitude: item.assetLongitude
        };
      })
    };
  }

  async fetchPageData(): Promise<string[]> {
    return await insightsGateway.modelTreeAssetsControllerGetSegmentsAll();
  }
}

export class ModelTreeAssetsServiceMock implements ModelTreeAssetsService {
  async fetchTableData(queryParams: ListParams): Promise<TableDataResponse> {
    return interceptor(async () => {
      await wait(1000);
      return {
        items: modelTreeAssetsMocks.tableData.map((el) => {
          return { ...el, key: uuidv4() };
        }),
        meta: modelTreeAssetsMocks.tableDataMetaMock
      };
    });
  }

  async fetchPageData(): Promise<string[]> {
    return interceptor(async () => {
      await wait(1000);
      return ['office', 'residential', 'logistic'];
    });
  }
}
