import { ComponentProps, Icon } from '@components';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import './rc-tabs.css';
import cn from 'classnames';

interface TabItem {
  key: string;
  label: string;
  children?: React.ReactNode;
  disabled?: boolean;
  simulateContent?: boolean;
}

export interface TabsProps extends ComponentProps {
  /** Children wrapper class name */
  childrenClassName?: string;
  /** List of tabs */
  items: TabItem[];
  /** Active tab key */
  activeKey?: string;
  /** Tab bar gutter */
  tabBarGutter?: number;
  /** Layout direction of tabs component */
  direction?: 'ltr' | 'rtl';
  /**
   * Called when clicked on next button
   */
  handleNextClick?: () => void;
  /**
   * Called when clicked on prev button
   */
  handleBackClick?: () => void;
  /**
   * Called when tab is clicked
   */
  handleClickTab?: (key: string) => void;
}

export const TabsComponent: FC<TabsProps> = ({
  childrenClassName,
  items = [],
  activeKey = '',
  direction = 'ltr',
  handleClickTab,
  tabBarGutter = 18,
  className,
  style,
  dataTestId,
  id
}: TabsProps): ReactElement => {
  const [internalActiveKey, setActiveKey] = useState<string>(activeKey);

  useEffect(() => {
    setActiveKey(activeKey);
  }, [activeKey]);

  const handleTabClick = (key: string) => {
    if (handleClickTab) {
      handleClickTab(key);
    }
    setActiveKey(key);
  };

  return (
    <div id={id} style={style} data-testid={dataTestId} className={className}>
      <Tabs
        activeKey={internalActiveKey}
        defaultActiveKey={activeKey}
        onChange={handleTabClick}
        direction={direction}
        renderTabBar={() => (
          <ScrollableInkTabBar
            tabBarGutter={tabBarGutter}
            nextIcon={<Icon name="chevron-right" size="m" id="left-arrow" />}
            prevIcon={<Icon name="chevron-left" size="m" id="left-arrow" />}
          />
        )}
        renderTabContent={() => <TabContent />}
      >
        {items.map(
          ({
            key,
            label,
            children,
            simulateContent,
            disabled
          }: TabItem): ReactElement | null => {
            return (
              <TabPane
                className={cn({
                  'hide-tab': !children && !simulateContent,
                  'simulate-tab-content': simulateContent
                })}
                tab={label}
                key={key}
                disabled={disabled}
              >
                <div
                  {...(childrenClassName && { className: childrenClassName })}
                >
                  {children}
                </div>
              </TabPane>
            );
          }
        )}
      </Tabs>
    </div>
  );
};
