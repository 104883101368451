import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DataItem, MarkerItemResponse } from '../interface';
import { ListMetaInfo } from '@interfaces';
import { ModelTreeAssetsServiceApi } from '../ModelTreeAssetsService';
import { checkNoMoreData, getStatusNotificationTranslations } from '@services';
import { openStatusNotification } from '@xq/ui-kit';
import { t } from 'i18next';
import { defaultSectionColor, getChevronColor } from '../utils';
import useGetUrlParams from './useGetUrlParams';
import { ITEMS_PER_PAGE } from '@constants';

export function useModelTreeAssets() {
  const service = new ModelTreeAssetsServiceApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const { sortBy, sortOrder, isMapOpen } = useGetUrlParams();

  const [tableData, setTableData] = useState<DataItem[]>([]);
  const [segments, setSegments] = useState<string[]>([]);
  const [tableDataMeta, setTableDataMeta] = useState<ListMetaInfo>(null);
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [mapDataResponse, setMapDataResponse] = useState<MarkerItemResponse[]>(
    []
  );

  const getQueryParams = (page?: number) => ({
    page: page || tableDataMeta?.currentPage,
    limit: ITEMS_PER_PAGE,
    sortOrder: sortOrder,
    sortBy: sortBy
  });

  const fetchTableData = async () => {
    if (isTableLoading) return;

    try {
      setIsTableLoading(true);
      const { items, meta } = await service.fetchTableData(getQueryParams(1));
      setTableData(items);
      setTableDataMeta(meta);
    } catch (error) {
      console.error(error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const fetchPageData = async () => {
    try {
      const segments = await service.fetchPageData();
      setSegments(segments);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMapData = async () => {
    try {
      const { items } = await service.fetchTableData({
        page: 1,
        limit: 100000,
        sortOrder,
        sortBy
      });

      const mapData = items
        ?.filter((item) => item.assetLongitude && item.assetLatitude)
        ?.map((item) => ({
          key: item.key || '',
          title: item.asset_name.place || '',
          coordinates: {
            lat: item.assetLatitude,
            lng: item.assetLongitude
          },
          segment: item.asset_name.type,
          backgroundColor: defaultSectionColor
        }));

      setMapDataResponse(mapData);
    } catch (error) {
      console.error(error);
    }
  };

  const mapData = useMemo(() => {
    return mapDataResponse?.map((el) => ({
      ...el,
      backgroundColor: getChevronColor(segments, el?.segment)
    }));
  }, [mapDataResponse, segments]);

  const handleSort = (newSortBy: string, newSortOrder: string) => {
    searchParams.set('sortBy', newSortBy);
    searchParams.set('sortOrder', newSortOrder);
    setSearchParams(searchParams);
  };

  const handleViewToggle = () => {
    if (isMapOpen) {
      searchParams.delete('isMapOpen');
    } else {
      searchParams.set('isMapOpen', 'true');
    }
    setSearchParams(searchParams);
  };

  const loadMoreTableData = async () => {
    if (noMoreData || !tableDataMeta) return;

    try {
      const queryParams = getQueryParams(++tableDataMeta.currentPage);
      const response = await service.fetchTableData(queryParams);
      const { items, meta } = response;

      checkNoMoreData(items?.length, response?.meta?.totalItems, setNoMoreData);
      setTableData([...tableData, ...items]);
      setTableDataMeta(meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [sortOrder, sortBy]);

  useEffect(() => {
    fetchPageData();
    fetchMapData();
  }, []);

  return {
    tableData,
    segments,
    isTableLoading,
    noMoreData,
    mapData,
    isMapOpen,
    sortBy,
    sortOrder,
    handleSort,
    handleViewToggle,
    loadMoreTableData
  };
}
