import { colors } from '@xq/ui-kit';

const segmentColors = [
  // 60
  colors.segmentBlue60,
  colors.segmentGreen60,
  colors.segmentCyan60,
  colors.segmentPurple60,
  colors.segmentGold60,
  colors.segmentOrange60,
  colors.segmentRed60,
  colors.segmentBlack60,
  // 100
  colors.segmentBlue100,
  colors.segmentGreen100,
  colors.segmentCyan100,
  colors.segmentPurple100,
  colors.segmentGold100,
  colors.segmentOrange100,
  colors.segmentRed100,
  colors.segmentBlack100,
  // 40
  colors.segmentBlue40,
  colors.segmentGreen40,
  colors.segmentCyan40,
  colors.segmentPurple40,
  colors.segmentGold40,
  colors.segmentOrange40,
  colors.segmentRed40,
  colors.segmentBlack40,
  // 80
  colors.segmentBlue80,
  colors.segmentGreen80,
  colors.segmentCyan80,
  colors.segmentPurple80,
  colors.segmentGold80,
  colors.segmentOrange80,
  colors.segmentRed80,
  colors.segmentBlack80,
  // 20
  colors.segmentBlue20,
  colors.segmentGreen20,
  colors.segmentCyan20,
  colors.segmentPurple20,
  colors.segmentGold20,
  colors.segmentOrange20,
  colors.segmentRed20,
  colors.segmentBlack20
];

// Additional color in case if there are more types
export const defaultSectionColor = '#8F83CA';

export const getChevronColor = (segments: string[], segment: string) => {
  const index = segments?.findIndex((el) => segment === el);
  return segmentColors?.[index] || defaultSectionColor;
};
