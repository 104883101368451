import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import {
  colors,
  ComplexTable,
  ComplexTableDropdown,
  ComplexTableKpiRadio,
  ComplexTableSortState,
  DataProps,
  Icon,
  SortDirection
} from '@xq/ui-kit';
import cn from 'classnames';
import styles from './ModelTreeAssetsTable.module.scss';
import {
  mapSortFromKeyToSorterKey,
  mapSortFromSortedKeyToKey,
  mapSortOrderFromAntToBackend,
  mapSortOrderFromBackendToAnt
} from './utils/getSortOptions';
import { getKpiColumns, groups } from './utils/getKpiColumns';
import { ActiveRow, KpiColumn, TableKpiGroup } from './interface';
import { ColumnProps } from 'antd/es/table';
import {
  getDataColumns,
  getDropdownFields
} from '@pages/ModelTreeAssets/components/ModelTreeAssetsTable/utils/getDataColumns';
import { DataItem } from '@pages/ModelTreeAssets/interface';
import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { ModelTreeGlobalAssetListSortOptionsDTOFieldNameEnum } from '@xq/exquance-insights-gateway-frontend-client';

const MAX_SELECTED_ROWS = 3;

interface ModelTreeAssetsTableProps {
  data: DataItem[];
  segments: string[];
  withKpis: boolean;
  onLoading?: () => Promise<void>;
  isTableLoading?: boolean;
  noMoreData: boolean;
  sortBy: string;
  sortOrder: SortDirection;
  onSort: (sortBy: string, sortOrder: SortDirection) => void;
}

const defaultColors: string[] = [
  colors.palettesBlue20,
  colors.palettesOrange30,
  colors.palettesGreen20
];

export const ModelTreeAssetsTable: FC<ModelTreeAssetsTableProps> = (props) => {
  const {
    data,
    segments,
    withKpis,
    onLoading,
    isTableLoading,
    noMoreData,
    sortBy,
    sortOrder,
    onSort
  } = props;

  // Selected row index + color
  const [selectedRowIndexes, setSelectedRowIndex] = useState<ActiveRow[]>([]);

  const [kpiGroups, setKpiGroups] = useState<TableKpiGroup[]>([]);

  //todo add enum
  const [selectedKpi, setSelectedKpi] = useState<string>('gla_sqm_group_1');
  const [selectedKpiGroup, setSelectedKpiGroup] = useState('group_1');
  const [activeColors, setActiveColors] = useState<string[]>(defaultColors);

  const sortState: ComplexTableSortState = useMemo(() => {
    return {
      order: mapSortOrderFromBackendToAnt(sortOrder),
      columnKey: mapSortFromSortedKeyToKey(sortBy)
    };
  }, [sortBy, sortOrder]);

  const allKpiColumns = useMemo(() => {
    return getKpiColumns(selectedRowIndexes, selectedKpi, sortState);
  }, [selectedRowIndexes, selectedKpi, sortState]);

  // change kpi group on kpi or row selection
  useEffect(() => {
    if (withKpis) {
      const kpiGroups: TableKpiGroup[] = Object.entries(groups).map(
        ([key, label]) => ({
          key,
          label,
          simulateContent: false
        })
      );

      // Should be in the opposite order because RTL layout is used
      setKpiGroups(kpiGroups.reverse());
    }
  }, [selectedRowIndexes, selectedKpi, withKpis]);

  //generate base columns (without kpis)
  // todo check selectedDataColumnKeys dependency
  const getBaseColumns = (
    handleDataColumnSelect: (value: string) => void,
    handleKpiColumnSelect: (value: string) => void,
    kpiCols: KpiColumn[],
    selectedKPIColumns: string[]
  ): ColumnProps<DataProps>[] => {
    const columns: ColumnProps<DataProps>[] = [
      {
        title: () => {
          return (
            <div>
              <ComplexTableDropdown
                title="Add/Hide column"
                placement="bottomLeft"
                items={getDropdownFields(selectedDataColumnKeys)}
                onItemSelect={handleDataColumnSelect}
                icon={
                  <Icon
                    className={styles.actionIcon}
                    size="s"
                    name="grid-settings"
                  />
                }
              />
            </div>
          );
        },
        width: 36,
        dataIndex: 'actions',
        hidden: false,
        id: 'actions',
        key: 'actions',
        fixed: 'left' as const,
        className: styles.dataHeaderCell,
        render: () => null
      },
      ...getDataColumns(sortState, segments)
    ] as ColumnProps<DataProps>[];

    if (withKpis) {
      columns.push({
        title: () => {
          return (
            <ComplexTableDropdown
              title="Add/Hide KPI"
              placement="bottomRight"
              items={kpiCols.map((column) => {
                return {
                  selected: selectedKPIColumns.includes(column.key as string),
                  title: column.displayTitle,
                  value: column.key as string
                };
              })}
              onItemSelect={handleKpiColumnSelect}
              icon={<Icon className={styles.actionIcon} size="s" name="plus" />}
            />
          );
        },
        width: '100%',
        hidden: false,
        dataIndex: 'kpi_actions',
        // todo fix
        //@ts-ignore
        id: 'kpi_actions',
        key: 'kpi_actions',
        fixed: 'right' as const,
        className: cn(styles.kpiHeaderCell, styles.addColCell),
        render: () => null
      });
    }

    return columns;
  };

  const kpiColumnsForSelectedGroup = allKpiColumns
    ?.filter((column) => column.group.key === selectedKpiGroup)
    ?.map((kpiData) => ({
      ...kpiData,
      title: () => (
        <ComplexTableKpiRadio
          name={kpiData.key}
          checked={selectedKpi === kpiData.key}
          title={kpiData.title as unknown as FC}
          displaySelectors={selectedRowIndexes?.length > 0}
          onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
            event.stopPropagation();
            return setSelectedKpi(kpiData.key);
          }}
        />
      )
    }));

  //todo duplicate?
  const allKpiData = useMemo(() => {
    return allKpiColumns?.map((kpiColumn) => ({
      ...kpiColumn,
      title: () => (
        <ComplexTableKpiRadio
          name={kpiColumn.key}
          checked={selectedKpi === kpiColumn.key}
          title={kpiColumn.title as unknown as FC}
          displaySelectors={selectedRowIndexes?.length > 0}
          onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
            event.stopPropagation();
            return setSelectedKpi(kpiColumn.key);
          }}
        />
      )
    }));
  }, [allKpiColumns, selectedRowIndexes, selectedKpi]);

  // Function called when we change selection of columns (left top filter button)
  const handleDataColumnSelect = (value: string) => {
    if (selectedDataColumnKeys.includes(value)) {
      setSelectedDataColumnKeys(
        selectedDataColumnKeys.filter((el) => el !== value)
      );
    } else {
      setSelectedDataColumnKeys([...selectedDataColumnKeys, value]);
    }
  };

  const handleKpiColumnSelect = (value: string) => {
    if (selectedKPIColumns.includes(value)) {
      setSelectedKPIColumns(selectedKPIColumns.filter((el) => el !== value));
    } else {
      setSelectedKPIColumns([...selectedKPIColumns, value]);
    }
  };
  const [selectedKPIColumns, setSelectedKPIColumns] = useState<string[]>(
    kpiColumnsForSelectedGroup
      .filter((value) => !value.hidden)
      .map((value) => value.key)
  );

  // left side columns (no KPIs)
  const baseColumns: ColumnProps<DataProps>[] = getBaseColumns(
    handleDataColumnSelect,
    handleKpiColumnSelect,
    allKpiColumns,
    selectedKPIColumns
  );

  // Keys that are shown in the table (left side)
  const [selectedDataColumnKeys, setSelectedDataColumnKeys] = useState<Key[]>(
    baseColumns?.filter((value) => !value.hidden).map((value) => value.key)
  );

  // Columns related to KPIs filtered for the group
  const filteredKpiColumns = useMemo(() => {
    return allKpiData?.map((item) => ({
      ...item,
      hidden: !selectedKPIColumns.includes(item.key as string)
    }));
  }, [allKpiData, selectedKPIColumns]);

  // Main columns array to render:
  // Columns related to base data + filteredKpiColumns if necessary
  const filteredDataColumns = useMemo(() => {
    const filteredDataColumns = baseColumns?.map((item) => ({
      ...item,
      hidden: !selectedDataColumnKeys?.includes(item.key as string)
    }));

    if (withKpis) {
      filteredDataColumns.splice(
        baseColumns.length - 1,
        0,
        ...filteredKpiColumns
      );
    }
    return filteredDataColumns;
  }, [baseColumns, filteredKpiColumns, selectedDataColumnKeys, withKpis]);

  // Function on select row
  const handleRowClick = (index: number, key: string) => {
    setSelectedRowIndex((prevState) => {
      const isRowSelected = prevState.some((row) => row.key === key);

      if (isRowSelected) {
        // Add row to selected
        const updatedRows = prevState.filter((row) => row.key !== key);
        const removedColor = prevState.find((row) => row.key === key)?.color;

        if (removedColor) {
          setActiveColors((prevColors) => [...prevColors, removedColor]);
        }

        return updatedRows;
      } else {
        // todo show notification
        // Remove row from selected
        if (prevState.length >= MAX_SELECTED_ROWS) {
          console.error(
            `[DEBUG]: Only ${MAX_SELECTED_ROWS} rows can be selected`
          );
          return prevState;
        }

        const newColor = activeColors[activeColors.length - 1];
        setActiveColors((prevColors) => prevColors.slice(0, -1));

        return [...prevState, { index, color: newColor, key }];
      }
    });
  };

  // On select kpi group, reset selection
  // todo reuse as init?
  const handleSelectKpiGroup = (key: string) => {
    setSelectedRowIndex([]);
    // todo simplify this logic?
    setActiveColors(defaultColors);

    const kpiColumns = getKpiColumns(
      selectedRowIndexes,
      selectedKpi,
      sortState
    );

    const filteredKpiColumns = kpiColumns.filter(
      (column) => column.group.key === key
    );

    setSelectedKpi(filteredKpiColumns[0].key);
    setSelectedKpiGroup(key);

    const kpiSelectedColumns = filteredKpiColumns.map((kpiSelectedColumn) => ({
      ...kpiSelectedColumn,
      title: () => (
        <ComplexTableKpiRadio
          name={kpiSelectedColumn.key}
          checked={selectedKpi === kpiSelectedColumn.key}
          title={kpiSelectedColumn.title as unknown as FC}
          displaySelectors={selectedRowIndexes?.length > 0}
          onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
            event.stopPropagation();
            return setSelectedKpi(kpiSelectedColumn.key);
          }}
        />
      )
    }));

    setSelectedKPIColumns(
      kpiSelectedColumns
        .filter((value) => !value.hidden)
        .map((value) => value.key)
    );
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: ComplexTableSortState
  ) => {
    onSort(
      mapSortFromKeyToSorterKey(
        sorter.columnKey
      ) as ModelTreeGlobalAssetListSortOptionsDTOFieldNameEnum,
      mapSortOrderFromAntToBackend(sorter.order)
    );
  };

  return (
    <ComplexTable
      id="complex-table"
      data={data}
      filteredDataColumns={filteredDataColumns}
      kpiGroups={kpiGroups}
      handleSelectKpiGroup={handleSelectKpiGroup}
      selectedKpiGroup={selectedKpiGroup}
      handleRowClick={handleRowClick}
      onChange={handleTableChange}
      onLoading={onLoading}
      isLoading={isTableLoading}
      noMoreData={noMoreData}
      maxHeight={'80vh'}
    />
  );
};

ModelTreeAssetsTable.displayName = 'ModelTreeAssetsTable';
