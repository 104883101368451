import React, { FC } from 'react';
import { ComponentProps, Loader, TabsComponent } from '@components';
import { ConfigProvider, Table, TablePaginationConfig } from 'antd';
import styles from './ComplexTable.module.scss';
import { ColumnProps } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import { ComplexTableSortState } from '@components/ComplexTable/interfaces';
import useActions, {
  COMPLEX_TABLE_ID
} from '@components/ComplexTable/hooks/useActions';
import classNames from 'classnames';
import { colors } from '@componentsStyles';

export interface ComplexTableProps<T> extends ComponentProps {
  id?: string;
  initialKpi?: string;
  initialKpiGroup?: string;
  data: DataItem[];
  kpiGroups: {
    key: string;
    label: string;
    simulateContent?: boolean;
  }[];
  handleSelectKpiGroup: (key: string) => void;
  selectedKpiGroup: string;
  filteredDataColumns: ColumnProps<DataProps>[];
  handleRowClick: (index: number, key: string) => void;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: ComplexTableSortState
  ) => void;
  isLoading?: boolean;
  onLoading?: () => Promise<void>;
  noMoreData?: boolean;
  minHeight?: string;
  maxHeight?: string;
}

type AssetName = {
  country: string;
  place: string;
  address: string;
  type: string;
};

type DataItem = {
  key: string;
  asset_name: AssetName;
  number: number;
  project: string;
  city: string;
  submarket: string;
  last_analysis_date?: string;
  [key: `gla_sqm_group_${number}`]: string;
  [key: `vacancy_rate_tech_group_${number}`]: string;
  [key: `vacancy_rate_econ_group_${number}`]: string;
  [key: `lease_length_years_group_${number}`]: string;
};

export interface DataProps {
  key: string;
  asset_name: { country: string; place: string; address: string; type: string };
  number: number;
  city: string;
  project: string;
  submarket: string;
  last_analysis_date?: string;

  [key: `gla_sqm_group_${number}`]: string;

  [key: `vacancy_rate_tech_group_${number}`]: string;

  [key: `vacancy_rate_econ_group_${number}`]: string;

  [key: `lease_length_years_group_${number}`]: string;
}

export const ComplexTable: FC<ComplexTableProps<DataProps>> = ({
  id,
  style,
  className,
  data,
  kpiGroups,
  handleSelectKpiGroup,
  selectedKpiGroup,
  filteredDataColumns,
  handleRowClick,
  onChange,
  onLoading,
  isLoading,
  noMoreData,
  minHeight = '400px',
  maxHeight = '500px'
}) => {
  const { isLoadingMore, onScroll, ref } = useActions({
    onLoading,
    noMoreData
  });

  const loadingMoreRow = () => (
    <div className={styles.footer}>
      <Loader color="orange" size="m" />
    </div>
  );

  return (
    <div className={styles.gridWrapper}>
      <TabsComponent
        className={styles.tabsContainer}
        items={kpiGroups}
        direction="rtl"
        handleClickTab={handleSelectKpiGroup}
        activeKey={selectedKpiGroup}
      />
      <div
        onScroll={onScroll}
        id={COMPLEX_TABLE_ID}
        ref={ref}
        style={{ ...style, minHeight, maxHeight }}
        className={classNames(className, styles.table)}
      >
        <ConfigProvider
          theme={{
            components: {
              Table: {
                footerBg: colors.whiteColor
              }
            }
          }}
        >
          <Table
            className={className}
            id={id}
            style={style}
            showSorterTooltip={false}
            columns={filteredDataColumns}
            dataSource={data}
            onChange={onChange}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  const key = (
                    record.asset_name?.place + record.asset_name?.country
                  )
                    .toLowerCase()
                    .replace(/ /g, '_');

                  return handleRowClick(index + 1, key);
                }
              };
            }}
            pagination={false}
            loading={
              isLoading && {
                fullscreen: false,
                indicator: <Loader color={'orange'} />,
                size: 'large'
              }
            }
            scroll={{ x: 'max-content' }}
            // footer={() => (
            //   <div className={styles.footer}>
            //     <Loader color="orange" size="m" />
            //   </div>
            // )}
            footer={isLoadingMore && loadingMoreRow}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

ComplexTable.displayName = 'ComplexTable';
