import type { Marker } from '@googlemaps/markerclusterer';
import React, { useCallback } from 'react';
import { Chevron, MarkerItem } from '@components';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import styles from '../MapCluster.module.scss';

export type MarkerItemMarkerProps = {
  markerItem: MarkerItem;
  onClick: (markerItem: MarkerItem) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
};

/**
 * Wrapper Component for an AdvancedMarker for a single markerItem.
 */
export const MapClusterMarker = (props: MarkerItemMarkerProps) => {
  const { markerItem, onClick, setMarkerRef } = props;

  const handleClick = useCallback(
    () => onClick(markerItem),
    [onClick, markerItem]
  );
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      setMarkerRef(marker, markerItem.key),
    [setMarkerRef, markerItem.key]
  );

  return (
    <AdvancedMarker
      position={markerItem.coordinates}
      ref={ref}
      onClick={handleClick}
    >
      <div className={styles.indicator}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <circle
            cx="8"
            cy="8"
            r="3.5"
            fill={markerItem.backgroundColor}
            stroke="white"
          />
        </svg>
        <Chevron backgroundColor={markerItem.backgroundColor}>
          {markerItem.title}
        </Chevron>
      </div>
    </AdvancedMarker>
  );
};
