import styles from '@pages/ModelTreeAssets/components/ModelTreeAssetsTable/ModelTreeAssetsTable.module.scss';
import {
  colors,
  ComplexTableAssetCell,
  ComplexTableSortState,
  CountryIso3,
  Typography
} from '@xq/ui-kit';
import { getSortOptions } from '@pages/ModelTreeAssets/components/ModelTreeAssetsTable/utils/getSortOptions';
import React, { Key } from 'react';

export const columnsSortKeyConfig: { key: string; sorterKey: string }[] = [
  {
    key: 'asset_name',
    sorterKey: 'asset_name'
  },
  { key: 'number', sorterKey: 'asset_number' },
  { key: 'city', sorterKey: 'asset_city_name' },
  {
    key: 'submarket',
    sorterKey: 'asset_submarket_name'
  },
  { key: 'project', sorterKey: 'project_name' },
  {
    key: 'last_analysis_date',
    sorterKey: 'valuation_date'
  }
];

const baseColumnsConfig = (segments: string[]) => [
  {
    id: 'asset_name',
    key: 'asset_name',
    displayTitle: 'Asset name',
    hidden: false,
    fixed: 'left' as const,
    canBeHidden: false,
    render: (asset_name: {
      country: CountryIso3;
      place: string;
      address: string;
      type: string;
    }) => (
      <ComplexTableAssetCell
        country={asset_name.country}
        place={asset_name.place}
        address={asset_name.address}
        type={asset_name.type}
        types={segments}
      />
    )
  },
  { key: 'number', displayTitle: 'Number' },
  { key: 'city', displayTitle: 'City' },
  {
    key: 'submarket',
    displayTitle: 'Submarket'
  },
  { key: 'project', displayTitle: 'Project' },
  {
    key: 'last_analysis_date',
    displayTitle: 'Last Analysis Date',
    hidden: false
  }
];

/** Get static columns (left section columns)
 *
 * @param sortState
 * @param segments
 */
export const getDataColumns = (
  sortState?: ComplexTableSortState,
  segments?: string[]
) => {
  const commonColumnProps = {
    hidden: false,
    className: styles.dataHeaderCell
  };

  //todo check this KPIs with backend keys

  return baseColumnsConfig(segments).map(
    ({ key, displayTitle, hidden, id, fixed, render, canBeHidden }) => ({
      ...commonColumnProps,
      key: key,
      dataIndex: key,
      displayTitle,
      hidden: hidden ?? commonColumnProps.hidden,
      title: () => (
        <Typography variant="system-heading" color={colors.gray40Color}>
          {displayTitle}
        </Typography>
      ),
      ...getSortOptions(sortState, key),
      id: id ?? undefined,
      fixed: fixed ?? undefined,
      render: render ?? undefined,
      canBeHidden: canBeHidden ?? true
    })
  );
};

export const getDropdownFields = (selectedDataColumnKeys: Key[]) => {
  return getDataColumns()
    ?.filter((column) => column.canBeHidden)
    ?.map((column) => {
      return {
        selected: selectedDataColumnKeys.includes(column.key as string),
        title: column.displayTitle,
        value: column.key
      };
    });
};
