import React, { FC, useEffect } from 'react';
import { ControlPosition, Map, useMap } from '@vis.gl/react-google-maps';
import { Coordinates } from '@components/Map/dataTypes';
import { ClusteredMarkers } from '@components/MapCluster/components/ClusteredMarkers';

export interface MapClusterProps {
  markersData: MarkerItem[];
  options?: {
    disableDefaultUI?: boolean;
    fullscreenControl?: boolean;
    streetViewControl?: boolean;
    zoomControl?: boolean;
    mapTypeControl?: boolean;
  };
  mapId: string;
}

export interface MarkerItem {
  key: string;
  title: string;
  coordinates: Coordinates;
  backgroundColor: string;
}

export const MapClusterMap: FC<MapClusterProps> = (props) => {
  const { markersData, mapId, options = { disableDefaultUI: true } } = props;

  useEffect(() => {
    console.log('markersData', markersData);
  }, [markersData]);

  const map = useMap();

  useEffect(() => {
    if (!map) {
      console.log('The map is not initialized');
      return;
    }

    if (markersData.length === 0) {
      console.log('No markersData', markersData);
      return;
    }

    if (
      typeof window == 'undefined' ||
      !window?.google ||
      !window.google.maps
    ) {
      console.log('Google Maps API not loaded', window.google);
      return;
    }

    console.log('The map is initialized', map);

    const bounds = new window.google.maps.LatLngBounds();

    markersData.forEach(({ coordinates }) => {
      bounds.extend(
        new window.google.maps.LatLng(coordinates.lat, coordinates.lng)
      );
    });

    console.log('bounds', bounds);

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }
  }, [map, markersData, window.google]);

  return (
    <Map
      style={{ height: '80vh' }}
      mapId={mapId}
      disableDefaultUI={options.disableDefaultUI}
      streetViewControl={options.streetViewControl}
      fullscreenControl={options.fullscreenControl}
      zoomControl={options.zoomControl}
      mapTypeControl={options.mapTypeControl}
      controlSize={24}
      fullscreenControlOptions={{ position: ControlPosition.TOP_RIGHT }}
      zoomControlOptions={{ position: ControlPosition.TOP_RIGHT }}
      clickableIcons={false}
      styles={[
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }]
        }
      ]}
    >
      {markersData && <ClusteredMarkers markersData={markersData} />}
    </Map>
  );
};

MapClusterMap.displayName = 'MapClusterMap';
