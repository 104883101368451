import React, { FC, Fragment } from 'react';
import { Button, MapCluster, Row } from '@xq/ui-kit';
import { ModelTreeAssetsTable } from './components/ModelTreeAssetsTable/ModelTreeAssetsTable';
import { config } from '@config';
import styles from './ModelTreeAssets.module.scss';
import { useModelTreeAssets } from './hooks/useModelTreeAssets';

export const ModelTreeAssets: FC = () => {
  const {
    tableData,
    segments,
    isTableLoading,
    noMoreData,
    mapData,
    isMapOpen,
    sortBy,
    sortOrder,
    handleSort,
    handleViewToggle,
    loadMoreTableData
  } = useModelTreeAssets();

  return (
    <Fragment>
      <Row>
        <div className={styles.controls}>
          <div>{/*todo add filters*/}</div>
          <div>
            <Button
              type="fourth"
              icon={!isMapOpen ? 'map' : 'list'}
              onClick={handleViewToggle}
            >
              {!isMapOpen ? 'Map view' : 'List view'}
            </Button>
          </div>
        </div>
        {!isMapOpen ? (
          <ModelTreeAssetsTable
            withKpis={false}
            data={tableData}
            segments={segments}
            onLoading={loadMoreTableData}
            isTableLoading={isTableLoading}
            noMoreData={noMoreData}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={handleSort}
          />
        ) : (
          <MapCluster
            mapId={config.googleMapClusterId}
            googleMapsApiKey={config.googleMapsApiKey}
            markersData={mapData}
            options={{
              disableDefaultUI: true,
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: true,
              mapTypeControl: false
            }}
          />
        )}
      </Row>
    </Fragment>
  );
};

ModelTreeAssets.displayName = 'ModelTreeAssets';
