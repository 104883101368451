import { Typography } from '@components';
import cn from 'classnames';
import styles from '@components/ComplexTable/ComplexTable.module.scss';
import React, { ReactElement } from 'react';

interface Props {
  activeRow: { color: string; index: number } | null;
  data: Record<string, string>;
  activeKpi: string;
  value: string;
}

export const ComplexTableKpiTypography = ({
  data,
  activeKpi,
  value,
  activeRow
}: Props): ReactElement => {
  return (
    <Typography
      className={cn(styles.kpiCell, {
        [styles.kpiCellSelection]: activeRow && data[activeKpi] === value
      })}
      style={
        activeRow && data[activeKpi] === value
          ? ({
              '--kpiColor': activeRow.color
            } as React.CSSProperties)
          : {}
      }
      variant="number-1"
    >
      {value}
    </Typography>
  );
};

export default ComplexTableKpiTypography;
