import { useSearchParams } from 'react-router-dom';
import { SortDirection } from '@xq/ui-kit';
import { SORT_DIRECTIONS } from '@constants';
import { ModelTreeGlobalAssetListSortOptionsDTOFieldNameEnum } from '@xq/exquance-insights-gateway-frontend-client';

function useGetUrlParams() {
  const [searchParams] = useSearchParams();

  const selectedKpi = searchParams.get('kpi');
  const isMapOpen = searchParams.get('isMapOpen') === 'true';
  const selectedDate = Number(searchParams.get('date'));
  const sortOrder: SortDirection = (searchParams.get('sortOrder') ||
    SORT_DIRECTIONS.ASC) as SortDirection;
  const sortBy = (searchParams.get('sortBy') ||
    'asset_name') as ModelTreeGlobalAssetListSortOptionsDTOFieldNameEnum;

  return {
    selectedKpi,
    selectedDate,
    isMapOpen,
    sortOrder,
    sortBy
  };
}

export default useGetUrlParams;
